.about {
  width: 80%;
  margin-left: 7rem;
  margin-right: 7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.heading {
  width: 30%;
  font-size: 1.15rem;
}
._img {
  margin: 10em;
  width: 35em;
  height: 20em;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.51);
}
._content_wrapper {
  width: 40%;
}
.contact-btn {
  margin-right: 20px;
  text-decoration: none;
  color: #464646;
}
.contact-btn:hover {
  margin-right: 20px;
  text-decoration: none;
  background-color: #ec704c;
}
@media (max-width: 1024px) {
  .about {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  ._img {
    margin: 2em;
  }
  ._content_wrapper {
    width: 40%;
  }
}
@media (max-width: 768px) {
  .about {
    margin-left: 4em;
    margin-right: 4em;
    flex-direction: column;
  }
  ._img {
    width: 15em;
    height: 12em;
  }
  ._content_wrapper {
    width: 15em;
  }
}